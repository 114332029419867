import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";
import { fetchTenantsData } from "../api";
import useUserStore from "../../../services/userStore";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  role: Yup.object().required("Role is required"),
  selectedMachine: Yup.array()
    .of(Yup.object())
    .required("Machines are required"),
});

const UserRoles = () => {
  const userData = useUserStore((state) => state.user);
  const tenant = myLocalStorage.getItem("latestTenant");
  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [tenantsList, setTenantsList] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const { role, email, userId, userRoleId, userRole } = useUserStore(
    (state) => state.user,
  );
  const [selectedRoleType, setSelectedRoleType] = useState(null);
  const [selectedRoleName, setSelectedRoleName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editedRole, setEditedRole] = useState(null);
  const [rolesList, setRolesList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [radioRoleType, setRadioRoleType] = useState("");
  const [roleTypeFilter, setRoleTypeFilter] = useState("users");
  const [machineList, setMachineList] = useState([]);

  const handleEditClick = (index, user) => {
    setRoleTypeFilter(selectedRoleType);
    setSelectedRole(user.roleName);
    setEditedRole(user);
    setOpenDialog(true);
  };

  const handleSaveEdit = async (values, { setSubmitting }) => {
    try {
      const computerIdList = values.selectedMachine.map(
        (computer) => computer.id,
      );

      const computerListStr =
        computerIdList.length > 0 ? computerIdList.join(",") : "";

      const response = await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/updateRoles`,
        values.role,
        {
          params: {
            roleType: selectedRoleType,
            email: editedRole.email,
            computerList: computerListStr,
          },
        },
      );

      console.log("Response:", response.data);

      setEditedRole(null);
      setOpenDialog(false); // Close the dialog
    } catch (error) {
      console.error("Error updating role:", error);
    } finally {
      setSubmitting(false); // Reset form submitting state
    }
  };

  const handleCancelEdit = () => {
    setEditedRole(null);
    setOpenDialog(false);
  };

  const fetchRolesList = async () => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/role-details`,
    );
    setRolesList(response?.data);
  };

  const fetchMachinesList = async () => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/getAllUserMachines`,
    );
    setMachineList(response?.data);
  };

  const filteredRoles = rolesList.filter((role) => {
    if (roleTypeFilter === "users") {
      return role.roleType === "users" && role.roleName !== "super-user";
    }
    return role.roleType === "admin";
  });

  const getRolesUserDetails = async (isFirst = false) => {
    if (isFirst) {
      setLoading(true);
    }
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/getRolesLevelUsers`,
    );
    const fetchedRolesData = response?.data;

    setRolesData(fetchedRolesData);
    if (isFirst) {
      const firstRoleType = Object.keys(fetchedRolesData?.roles || [])[0];
      if (firstRoleType) {
        if (
          userData?.userRole === "epam-admin" ||
          userData?.userRole === "server-pam-admin"
        ) {
          setSelectedRoleType("users");
        } else {
          setSelectedRoleType(firstRoleType);
        }

        setSelectedRoleName(null);
      }
    }
    setLoading(false);
  };

  console.log(selectedRoleType);

  const roleNamesList = selectedRoleType
    ? Object.keys(rolesData.roles[selectedRoleType])
    : [];
  const users =
    selectedRoleType && selectedRoleName
      ? rolesData.roles[selectedRoleType][selectedRoleName]
      : [];

  const handleRadioChange = (event) => {
    setRadioRoleType(event.target.value);
    setSelectedRoleName(null);
  };

  const handleRoleSelect = (event, newValue) => {
    setSelectedRole(newValue?.roleName || "");
  };

  useEffect(() => {
    if (email) fetchTenantsData(email, setTenantsList);
  }, [email]);

  useEffect(() => {
    if (tenantsList.length === 1) return setSelectedTenant(tenantsList[0]);

    if (tenantsList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantsList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );

      if (tenant) setSelectedTenant(tenant);
      else setSelectedTenant(tenantsList[0]);
    }
  }, [tenantsList]);

  useEffect(() => {
    getRolesUserDetails(true);
    fetchRolesList();
    fetchMachinesList();
    setEditedRole(null);

    const interval = setInterval(() => {
      getRolesUserDetails(false);
      fetchRolesList();
      fetchMachinesList();
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedTenant]);

  return (
    <>
      <Box>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
      </Box>

      <Box padding={3}>
        {loading ? (
          <CircularProgress />
        ) : rolesData.length === 0 ? (
          <Typography variant="body1"> No data found</Typography>
        ) : (
          <Stack direction="row" spacing={2}>
            <Box width="30%">
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    height: "fit-content",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Role Types</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(rolesData?.roles)
                      .map((roleType, index) => {
                        if (
                          userRole === "server-pam-admin" ||
                          userRole === "epam-admin"
                        ) {
                          return roleType === "users" ? roleType : null;
                        }

                        return roleType;
                      })
                      .filter(Boolean)
                      .map((roleType, index) => (
                        <TableRow
                          key={index}
                          selected={selectedRoleType === roleType}
                          onClick={() => {
                            setSelectedRoleType(roleType);
                            setSelectedRoleName(null);
                            setEditedRole(null);
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "& td, & th": {
                              border: "1px solid #233044",
                              fontSize: "8px",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                          }}
                        >
                          <TableCell>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: 16,
                              }}
                            >
                              {roleType}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box width="20%">
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    height: "fit-content",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Role Names</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {roleNamesList.length > 0 ? (
                      roleNamesList
                        .filter((roleName) => {
                          if (userRole === "epam-admin") {
                            return roleName !== "server-pam-user";
                          }
                          if (userRole === "server-pam-admin") {
                            return roleName !== "epam-user";
                          }
                          return true;
                        })
                        .map((roleName, index) => (
                          <TableRow
                            key={index}
                            selected={roleName === selectedRoleName}
                            onClick={() => {
                              setSelectedRoleName(roleName);
                              setEditedRole(null);
                            }}
                            sx={{
                              "&:hover": {
                                background: "#f5f5f5",
                                cursor: "pointer",
                              },
                              "& td, & th": {
                                border: "1px solid #233044",
                                fontSize: "8px",
                              },
                              "&.Mui-selected": {
                                background: "#233044 !important",
                                "& td, & th": {
                                  color: "#fff",
                                },
                              },
                            }}
                          >
                            <TableCell>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: 16,
                                }}
                              >
                                {roleName}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell>No roles found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box width="50%">
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    height: "fit-content",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.length > 0 ? (
                      users.map((user, index) => (
                        <TableRow key={index}>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.roleName}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              onClick={() => handleEditClick(index, user)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>No users found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        )}
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCancelEdit}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Edit Role
          <IconButton
            aria-label="close"
            onClick={handleCancelEdit}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Formik
          initialValues={{
            role:
              rolesList.find(
                (data) => data.roleName === editedRole?.roleName,
              ) || "",
            selectedMachine: editedRole?.machineList || [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSaveEdit}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Box mb={2}>
                  <Autocomplete
                    options={filteredRoles}
                    getOptionLabel={(option) => option.roleName}
                    value={values.role}
                    disabled={userData.userRole === "epam-admin"}
                    onChange={(event, value) => {
                      setFieldValue("role", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Role"
                        variant="outlined"
                        error={touched.role && !!errors.role}
                        helperText={touched.role && errors.role}
                        fullWidth
                      />
                    )}
                  />
                </Box>

                {selectedRoleType === "users" &&
                values.role.roleName !== "thirdparty-user" ? (
                  <Box sx={{ mb: 2 }}>
                    <Autocomplete
                      multiple
                      options={machineList}
                      value={values.selectedMachine || []} // Bind the value prop
                      getOptionDisabled={() => {
                        const selectedMachinesCount =
                          values?.selectedMachine?.length || 0;
                        // Disable all options if selected machines count is 3 or more
                        return selectedMachinesCount >= 3;
                      }}
                      getOptionLabel={(option) =>
                        option.dNSHostName ||
                        option.IPV4Address ||
                        "Unknown Machine"
                      }
                      onChange={(event, value) => {
                        const uniqueMachines = value.filter(
                          (machine, index, self) =>
                            index ===
                            self.findIndex(
                              (m) =>
                                m.dNSHostName?.toLowerCase() ===
                                  machine.dNSHostName?.toLowerCase() &&
                                m.id === machine.id,
                            ),
                        );
                        if (uniqueMachines.length <= 3) {
                          setFieldValue("selectedMachine", uniqueMachines);
                        } else {
                          alert("You can select a maximum of 3 machines.");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Machines (Required for epam-user)"
                          variant="outlined"
                          error={
                            touched.selectedMachine && !!errors.selectedMachine
                          }
                          helperText={
                            touched.selectedMachine && errors.selectedMachine
                          }
                          fullWidth
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={
                              option.dNSHostName ||
                              option.IPV4Address ||
                              "Unknown Machine"
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </Box>
                ) : null}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleCancelEdit}
                  variant="outlined"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UserRoles;
